sf<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <div id="ordersSearchFixed">
        <div class="searchFixed">
          <v-card outlined elevation="2" class="ma-3">
            <v-card-text>
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('actions.search')"
                single-line
                :color="inputColor"
                hide-details
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
      <v-flex>
        <v-card
          id="ordersCard"
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>

            <v-spacer />

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  icon
                  ma-0
                  style="margin: 0;"
                  v-on="on"
                  @click="newItem"
                >
                  <v-icon class="yellow-color-text">mdi-plus-circle-outline</v-icon>
                </v-btn>  
              </template> 

              <span class="white--text">{{ $t(`${objectName.multiple}.titleNew`) }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              id="ordersSearch"
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
            />
            <v-data-table
              fixed-header
              height="600"
              :headers="headers"
              :items="data"
              :search="search"
              :options.sync="pagination"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left'
              }"
            >
              <template v-slot:body="{ items }">
                <tr v-for="item in items" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>{{ item.companyDepositoryName }}</td>
                  <td>{{ item.executionDate }}</td>
                  <td>{{ $t(`orders.states.${item.state}`) }}</td>
                  <td style="text-align: right; padding: 0;">
                    <v-btn
                      text
                      icon
                      rounded
                      color="info"
                      @click="showItem(item.id)"
                    >
                      <v-icon>mdi-file-find-outline</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="item.state === 'pending'"
                      text
                      icon
                      rounded
                      color="warning"
                      @click="editItem(item.id)"
                    >
                      <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-btn>

                    <v-btn
                      v-if="item.state === 'pending'"

                      text
                      icon
                      rounded
                      color="error"
                      @click="deleteItem(item.id)"
                    >
                      <v-icon>mdi-file-cancel-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <tr slot="no-results">
                <td :colspan="headers.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      id="formModal"
      v-model="formModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <!-- <v-card-title class="headline primary elevation-3" style="text-align: center; max-height: 58px;"><h5 class="white--text ma-0">{{ title }}</h5></v-card-title> -->
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout row wrap pa-1 align-center justify-center>
            <v-flex class="pl-1 pr-1" xs12 :class="object.categoryOrderId === '1' ? 'sm6' : ''">
              <v-select
                v-model="object.categoryOrderId"
                :items="categoryOrders"
                :label="$t(`${objectName.multiple}.categoryOrderId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="name"
                item-value="id"
                :error="objectErrors.categoryOrder !== undefined"
                :error-messages="parseErrors(objectErrors.categoryOrder)"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.cycleId"
                :items="cycles"
                :label="$t(`${objectName.multiple}.cycleId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="name"
                :error="objectErrors.cycle !== undefined"
                :error-messages="parseErrors(objectErrors.cycle)"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.financialAdvances"
                :items="generalOptions"
                :label="$t(`${objectName.multiple}.financialAdvances`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="text"
                :error="objectErrors.financialAdvances !== undefined"
                :error-messages="parseErrors(objectErrors.financialAdvances)"
                item-value="value"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.weightGaranty"
                :items="generalOptions"
                :label="$t(`${objectName.multiple}.weightGaranty`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="text"
                :error="objectErrors.weightGaranty !== undefined"
                :error-messages="parseErrors(objectErrors.weightGaranty)"
                item-value="value"
              />
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12>
              <v-autocomplete
                v-model="object.companyDepositoryId"
                :items="companyDepositoriesData"
                :label="$t(`${objectName.multiple}.companyDepositoryId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.companyDepositoryId !== undefined"
                :error-messages="parseErrors(objectErrors.fieldBatch)"
                item-text="name"
                item-value="id"
                @change="checkCompanyDepositoryId"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.productId"
                :items="products"
                :label="$t(`${objectName.multiple}.productId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.product !== undefined"
                :error-messages="parseErrors(objectErrors.product)"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.fieldBatchId"
                :items="customreFieldBatches"
                :label="$t(`${objectName.multiple}.fieldBatchId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.fieldBatch !== undefined"
                :error-messages="parseErrors(objectErrors.fieldBatch)"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12 sm4>
              <v-select
                v-model="object.installerId"
                :items="installers"
                :label="$t(`${objectName.multiple}.installerId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12 sm4>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="object.executionDate"
                persistent
                lazy
                full-width
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="object.executionDate"
                    :label="$t(`${objectName.multiple}.executionDate`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.executionDate !== undefined"
                    :error-messages="parseErrors(objectErrors.executionDate)"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="object.executionDate" scrollable>
                  <v-spacer />
                  <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.dialog.save(object.executionDate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12 sm4>
              <v-text-field
                v-model="object.bagsQuantity"
                type="number"
                :label="$t(`${objectName.multiple}.bagsQuantity`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.bagsQuantity !== undefined"
                :error-messages="parseErrors(objectErrors.bagsQuantity)"
                min="0"
                @change="adjustBags"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId !== '2'" class="pl-1 pr-1" xs12>
              <v-autocomplete
                v-model="object.kitsIds"
                :items="selectableKits()"
                :multiple="true"
                :label="$t(`${objectName.multiple}.kitsIds`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="helper"
                item-value="id"
              />
            </v-flex>
            <!-- <v-flex v-if="object.categoryOrderId !== '2'" class="pl-1 pr-1" xs12>
              <v-select
                v-model="object.sensors"
                :items="selectableLoraSensors()"
                :multiple="true"
                :label="$t(`${objectName.multiple}.sensors`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="deviceEui"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId !== '2'" class="pl-1 pr-1" xs12>
              <v-select
                v-model="object.bluetoothSensors"
                :items="selectableBtSensors()"
                :multiple="true"
                :label="$t(`${objectName.multiple}.bluetoothSensors`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="sensorId"
                item-value="id"
              />
            </v-flex> -->
            <v-flex v-if="object.categoryOrderId === '1'" class="pl-1 pr-1" xs12>
              <v-select
                v-model="object.sensorizationType"
                :items="sensorizationTypes"
                :label="$t(`${objectName.multiple}.sensorizationType`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="text"
                item-value="value"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1' && object.sensorizationType !== ''" class="pl-1 pr-1" xs12 :class="object.sensorizationType === 'ST' ? 'sm6' : ''">
              <v-text-field
                v-model="object.contractNumberComm"
                type="number"
                :label="$t(`${objectName.multiple}.contractNumberComm`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.contractNumberComm !== undefined"
                :error-messages="parseErrors(objectErrors.contractNumberComm)"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1' && object.sensorizationType === 'ST'" class="pl-1 pr-1" xs12 sm6>
              <v-text-field
                v-model="object.contractNumber"
                type="number"
                :label="$t(`${objectName.multiple}.contractNumber`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.contractNumber !== undefined"
                :error-messages="parseErrors(objectErrors.contractNumber)"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId === '1'" xs12>
              <v-data-table
                :headers="bagHeaders"
                :items="object.bags"
                :search="search"
                :options.sync="pagination"
                :footer-props="{
                  'next-icon': 'mdi-chevron-right',
                  'prev-icon': 'mdi-chevron-left'
                }"
              >
                <template v-slot:body="{ items }">
                  <tr v-for="item in items" :key="item.id">
                    <td>
                      <v-text-field
                        v-model="item.reference"
                        :label="$t(`bags.reference`)" 
                        :disabled="!isEditting"
                        :color="inputColor"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="item.estimatedWeight"
                        :label="$t(`bags.estimatedWeight`)"
                        type="number"
                        min="0" 
                        :disabled="!isEditting"
                        :color="inputColor"
                      />
                    </td>                
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
            <v-flex v-if="object.categoryOrderId !== '1'" class="pl-1 pr-1" xs12>
              <v-select
                v-model="object.bagsIds"
                :items="customerBags"
                :multiple="true"
                :label="$t(`${objectName.multiple}.bagsIds`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="reference"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId === '1'" xs12>
              <v-layout row wrap>
                <v-flex xs12 md4>
                  <v-switch 
                    v-model="object.configuration.email.active"
                    :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.email`)"
                  />
                </v-flex>

                <v-flex v-if="object.configuration.email.active" xs12 md8>
                  <v-autocomplete
                    v-model="object.configuration.email.destinations"
                    :filter="customFilter"
                    :items="availableSelectUsers"
                    :label="$t(`helpers.destinations`)"
                    :disabled="!isEditting"
                    :dark="dark"
                    multiple
                    item-value="id"
                    small-chips
                  >
                    <template slot="selection" slot-scope="{ item }">
                      <span>  {{ item.username }} {{ $t(`users.${item.parentType.split('::')[1]}`) }}  </span>
                    </template>

                    <template slot="item" slot-scope="{ item }">
                      <span>  {{ item.username }} {{ $t(`users.${item.parentType.split('::')[1]}`) }}  </span>
                    </template>
                  </v-autocomplete>                      
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                    <v-flex xs12 md6>
                      <v-switch 
                        v-model="object.configuration.twilio.active"
                        :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.twilio`)"
                      />
                    </v-flex>

                    <v-flex v-if="object.configuration.twilio.active" xs12 md6>
                      <v-switch 
                        v-model="object.configuration.twilio.approvedByCentral"
                        :disabled="!isEditting && user.parentType === 'Company::Central'" :label="$t(`helpers.approvedByCentral`)"
                      />
                    </v-flex>

                    <v-flex v-if="object.configuration.twilio.active" xs12 md6>
                      <v-autocomplete
                        v-model="object.configuration.twilio.destinations"
                        :items="availableSelectUsers"
                        :label="$t(`helpers.destinations`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                        multiple
                        small-chips
                        item-text="username"
                        item-value="id"
                      />                      
                    </v-flex>
                    <v-flex v-if="object.configuration.twilio.active" xs12 md6>
                      <v-autocomplete
                        v-model="object.configuration.twilio.callDestinations"
                        :items="availableSelectUsers"
                        :label="$t(`helpers.callDestinations`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                        multiple
                        small-chips
                        item-text="username"
                        item-value="id"
                      />                      
                    </v-flex>
                  </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <a-button v-if="object.id !== undefined" :state="buttonState" color="primary" @click="saveChanges">{{ $t('actions.save') }}</a-button>
          
          <a-button v-else-if="object.id === undefined" :state="buttonState" color="primary" @click="createItem">{{ $t('actions.create', { name: $t(`${objectName.multiple}.objectName`) }) }}</a-button>
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.close') }}</v-btn>
          
          <v-spacer />
          
          <v-btn color="primary" @click="editItem(object.id)">{{ $t('actions.edit', { name: $t(`${objectName.multiple}.objectName`)}) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline" style="font-size: 1.2em !important; text-align: center;">{{ $t('helpers.confirmDelete', { name: $t(`${objectName.multiple}.objectName`) }) }}</v-card-title>

        <v-card-actions style="text-align: center;">
          <v-spacer />

          <v-btn
            color="error"
            flat
            icon
            @click="deleteConfirm = false; deleteDialog = false; deleteId = 0"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

          <v-btn
            color="success"
            flat
            icon
            @click="deleteConfirmation"
          >
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'

import { mapState, mapGetters } from 'vuex'

import add from '@/graphql/mutations/orders/add.gql'
import edit from '@/graphql/mutations/orders/edit.gql'
import object from '@/graphql/queries/orders/detail.gql'
import moment from 'moment'

const objectName = {
  single: 'order',
  multiple: 'orders',
  object: {
    single: 'Order',
    multiple: 'Orders'
  }
}

export default {
  components: {
    AButton
  },

  data () {
    return {
      objectName,
      modal: false,
      pagination: { rowsPerPage: 10 },
      formModal: false,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      deleteId: 0,
      object: {},
      objectErrors: {},
      search: '',
      usableKits: [],
      usableBtSensors: [],
      usableLoraSensors: [],
      headers: [
        {
          text: this.$i18n.t(`${objectName.multiple}.id`),
          align: 'left',
          sortable: true,
          value: 'id'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.companyDepositoryId`),
          align: 'left',
          sortable: true,
          value: 'companyDepositoryName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.executionDate`),
          align: 'left',
          sortable: true,
          value: 'executionDate'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.state`),
          align: 'left',
          sortable: true,
          value: 'state'
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ],
      bagHeaders: [
        {
          text: this.$i18n.t(`bags.reference`)
        },
        {
          text: this.$i18n.t(`bags.estimatedWeight`)
        }
      ]
    }
  },

  computed: {
    ...mapState(['user', 'bags', 'width', 'dark', 'language', 'categoryOrders', 'cycles', 'products', 'fieldBatches', 'installers', 'loraSensors', 'btSensors', 'kits', 'companyDepositories', 'companyLocals', 'usersSelect']),
    // ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor', 'loraSensorsBtSensors', 'freeKitsLabeled', 'ownedKitsLabeled', 'freeBtSensors', 'freeLoraSensors', 'validFields', 'unclosedBags']),
    ...mapGetters({
      data: 'noFinishedOrders'
    }),
    availableSelectUsers () {
      if (this.object.companyDepositoryId === null) {
        return []
      }
      const companyDepositoryId = this.object.companyDepositoryId
      let companyLocalId = null
      try {
        companyLocalId = this.companyDepositories.find((companyDepository) => companyDepository.id === this.object.companyDepositoryId).parentId
      } catch (error) {
        
      }
      return this.usersSelect.filter((user) => (user.parentId === companyDepositoryId && user.parentType === 'Company::Depository') || 
      (user.parentId === companyLocalId && user.parentType === 'Company::Local') || (user.parentType === 'Company::Central'))
    },
    companyDepositoriesData () {
      // Sort the company depositories by name
      return this.companyDepositories.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    },
    generalOptions () {
      return [
        {
          text: this.$i18n.t(`actions.general.yes`),
          value: true
        },
        {
          text: this.$i18n.t(`actions.general.no`),
          value: false
        }
      ]
    },
    defaultObject () {
      return {
        id: undefined,
        categoryOrderId: 0,
        cycleId: 0,
        productId: 0,
        fieldBatchId: 0,
        installerId: 0,
        executionDate: moment().format('YYYY-MM-DD'),
        sensors: [],
        bluetoothSensors: [],
        bagsQuantity: 0,
        kitsIds: [],
        bags: [],
        bagsIds: [],
        sensorizationType: 'SP',
        contractNumber: '',
        contractNumberComm: '',
        financialAdvances: false,
        weightGaranty: false,
        configuration: {
          email: {
            active: false,
            destinations: []
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: [],
            approvedByCentral: false
          }
        }
      }
    },

    isFullScreen () {
      return this.width.full < 990
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.name })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.name })
        }
      }
    },
    customreFieldBatches () {
      return this.validFields.filter((bag) => bag.companyDepositoryId === this.object.companyDepositoryId)
    },
  
    customerBags () {
      return this.unclosedBags.filter((bag) => bag.companyDepositoryId === this.object.companyDepositoryId)
    },
    customerKits () {
      return this.kits.filter((kit) => kit.companyDepositoryId === this.object.companyDepositoryId)
    },
    sensorizationTypes () {
      return [
        {
          text: this.$i18n.t('sensorization.sp'),
          value: 'SP'
        },
        {
          text: this.$i18n.t('sensorization.st'),
          value: 'ST'
        }
      ]
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted () {
    const card = document.querySelector('#ordersCard')
    const search = document.getElementById('ordersSearchFixed')

    var observer = new ResizeObserver(function (entries) {
      entries.forEach(function (entry) {
        search.style.width = `${card.offsetWidth}px`
      })
    })

    observer.observe(card)
  },

  methods: {
    customFilter (item, query, arr) {
      const name = item.username.toLowerCase() + '' + this.$i18n.t(`users.${item.parentType.split('::')[1]}`).toLowerCase()
      return name.includes(query)
    },
    handleScroll (event) {
      const elem = document.getElementById('ordersCard')
      const search = document.getElementById('ordersSearchFixed').childNodes[0]
      const searchCard = document.getElementById('ordersSearch')

      var posTopView = window.scrollY
      var elemTop = elem.offsetTop + 126

      if (elemTop > posTopView) {
        search.classList.remove('visible')
        if (this.keyFocus) {
          searchCard.focus()
          this.keyFocus = false
        }
      } else {
        search.classList.add('visible')
        this.keyFocus = true
      }
    },

    checkCompanyDepositoryId () {
      this.object.fieldBatchId = 0
      this.object.bags = []
    },
    selectableKits () {
      const kits = this.ownedKitsLabeled.filter((item) => this.usableKits.includes(item.id))
      const finalKits = [
        ...this.freeKitsLabeled,
        ...kits
      ]
      // Order the kits by name
      finalKits.sort((a, b) => {
        if (a.reference < b.reference) {
          return -1
        }
        if (a.reference > b.reference) {
          return 1
        }
        return 0
      })
      if (this.object.companyDepositoryId === undefined) {
        return finalKits
      }
      const companyLocalId = this.companyDepositories.find((companyDepository) => companyDepository.id === this.object.companyDepositoryId).parentId
      console.log(this.object.companyDepositoryId, this.companyDepositories)
      console.log(companyLocalId)
      console.log(finalKits)
      return finalKits.filter((kit) => kit.companyLocalId === companyLocalId)
    },
    selectableBtSensors () {
      const btSensors = this.btSensors.filter((item) => this.usableBtSensors.includes(item.id))
      const finalBtSensors = [
        ...this.freeBtSensors,
        ...btSensors
      ]
      return finalBtSensors
    },
    selectableLoraSensors () {
      const loraSensors = this.loraSensors.filter((item) => this.usableLoraSensors.includes(item.id))
      const finalLoraSensors = [
        ...this.freeLoraSensors,
        ...loraSensors
      ]
      return finalLoraSensors
    },
    adjustBags () {
      if (this.object.bagsQuantity < 0) {
        this.object.bagsQuantity = 0
        return
      }
      const bags = []
      for (var i = 0; i < this.object.bagsQuantity; i++) {
        if (this.object.bags[i] === undefined) {
          bags.push({
            reference: 'Nueva Bolsa',
            estimatedWeight: 0
          })
        } else {
          bags.push(this.object.bags[i])
        }
      }

      this.object.bags = bags
    },

    parseErrors (array) {
      if (array === undefined) {
        return []
      }
      return array.map((currentValue, index, array) => {
        return currentValue.charAt(0).toUpperCase() + currentValue.substr(1)
      }).join(', ')
    },

    saveChanges () {
      this.graphQLWrapper(`edit${objectName.object.single}`)
    },

    async editItem (id) {
      if (this.object.id === undefined) {
        await this.showItem(id)
      }
      
      this.isEditting = true
    },

    async showItem (id) {
      await this.$apollo.query({
        query: object,
        variables: {
          id,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const result = response.data[objectName.single]
        
        if (result.status) {
          this.object = {
            ...result[objectName.single]
          }
          this.formModal = true
          this.isEditting = false
          
          this.usableKits = this.object.kitsIds
          this.usableLoraSensors = this.object.sensors
          this.usableBtSensors = this.object.bluetoothSensors
        } else {
          if (errors.limit === undefined) {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: (errors.id === undefined ? errors.apiToken : errors.id)
            })
          } else {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: errors.limit
            })
          }
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      })
    },

    discardChanges () {
      this.usableKits = []
      this.usableLoraSensors = []
      this.usableBtSensors = []
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = false
      this.isEditting = false
    },

    newItem () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = true
      this.isEditting = true
    },

    createItem () {
      this.graphQLWrapper(`add${objectName.object.single}`)
    },

    graphQLWrapper (mutationName) {
      if (this.object.categoryOrderId === '2') {
        this.object.sensors = []
        this.object.kitsIds = []
        this.object.bluetoothSensors = []
      }
      this.buttonState = true
      this.objectErrors = {}
      const object = Object.assign({}, this.object)
      object.executionDate = moment(this.object.executionDate).unix()
      object.bagsQuantity = parseInt(object.bagsQuantity)
      for (var i = object.bags.length - 1; i >= 0; i--) {
        delete object.bags[i].__typename

        object.bags[i].estimatedWeight = parseFloat(object.bags[i].estimatedWeight)
      }
      const variables = {
        object: object,
        apiToken: this.user.apiToken,
        language: this.language.toUpperCase()
      }

      delete variables.object.__typename

      let mutation = add

      if (mutationName.includes('edit')) {
        mutation = edit
      }

      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        const { status, errors, result } = response.data[mutationName]

        if (status === 'OK') {
          this.object = {
            ...result
          }
          this.objectErrors = {}
          this.isEditting = false
          this.$store.dispatch(`get${objectName.object.multiple}`)
          this.$store.dispatch('getKits')
          this.$store.dispatch('getLoraSensors')
          this.$store.dispatch('getBtSensors')

          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`${objectName.multiple}.success.${(mutationName.includes('edit') ? 'updated' : 'created')}`, { name: result.name })
          })
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },

    async deleteItem (id) {
      this.deleteId = id
      this.deleteDialog = true
    },

    async deleteConfirmation () {
      await this.$store.dispatch('deleteItem', {
        mutation: {
          graphql: `delete${objectName.object.single}`,
          vuex: `get${objectName.object.multiple}`
        },
        object: objectName.multiple,
        variables: {
          id: this.deleteId,
          apiToken: this.user.apiToken,
          language: this.language
        }
      })

      this.deleteDialog = false
      this.deleteId = 0
      this.deleteConfirm = false
    }
  }

}
</script> 