<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <div id="sensorsSearchFixed">
        <div class="searchFixed">
          <v-card outlined elevation="2" class="ma-3">
            <v-card-text>
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('actions.search')"
                single-line
                :color="inputColor"
                hide-details
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
      <v-flex>
        <v-card
          id="sensorsCard"
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>
            
            <v-spacer />
            
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  slot="activator"
                  color="white"
                  text
                  icon
                  ma-0
                  rounded
                  style="margin: 0;"
                  v-on="on"
                  @click="newItem"
                >
                  <v-icon class="yellow-color-text">mdi-plus-circle-outline</v-icon>
                </v-btn>
              </template>

              <span class="white--text">{{ $t(`${objectName.multiple}.titleNew`) }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text class="py-2">
            <v-text-field
              id="sensorsSearch"
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
            />
            <v-data-table
              fixed-header
              height="600"
              :headers="headers"
              :items="data"
              :search="search"
              :options.sync="pagination"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left'
              }"
            >
              <template v-slot:body="{ items }">
                <tr v-for="(item, index) in items" :key="index">
                  <td>{{ item.deviceEui }}</td>
                  <td>{{ item.bagReference }}</td>
                  <td> {{ item.loraNodeName === null ? "NA" : item.loraNodeName }}</td>
                  <td>{{ item.isAlive ? 'Si' : 'No' }}</td>
                  <td>{{ parseDate(item.updatedAt) }}</td>
                  <td>{{ item.kitReference ? item.kitReference : $t(`${objectName.multiple}.noKitReference`) }}</td>
                  <td style="text-align: right; padding: 0;">
                    <v-btn
                      text
                      icon
                      rounded
                      color="info"
                      @click="showItem(item.id)"
                    >
                      <v-icon>mdi-file-find-outline</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      icon
                      rounded
                      color="warning"
                      @click="editItem(item.id)"
                    >
                      <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-btn>

                    <v-btn
                      text
                      icon
                      rounded
                      color="error"
                      @click="deleteItem(item.id)"
                    >
                      <v-icon>mdi-file-cancel-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <tr slot="no-results">
                <td :colspan="headers.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      id="formModal"
      v-model="formModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-card-title class="headline primary elevation-3" style="text-align: center; max-height: 58px;"><h5 class="white--text ma-0">{{ title }}</h5></v-card-title>
        <v-card-text style="flex: 1;" class="pa-0">
          <v-tabs
            color="primary"
            background-color="primary"
            class="text--white"
            slider-color="primary"
            grow
            dark
          >
            <v-tab>{{ $t(`${objectName.multiple}.tabs.basic`) }}</v-tab>
            <v-tab>{{ $t(`${objectName.multiple}.tabs.rules`) }}</v-tab>
            <v-tab-item class="px-5">
              <v-layout row wrap pa-1 align-center justify-center
                        pa-4
              >
                <!-- <v-flex class="pl-1 pr-1" xs12 sm6>
                  <v-text-field
                    v-model="object.name"
                    :label="$t(`${objectName.multiple}.name`)"
                    prepend-icon="mdi-label-variant"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.name !== undefined"
                    :error-messages="parseErrors(objectErrors.name)"
                  />
                </v-flex> -->

                <v-flex class="pl-1 pr-1" xs12 sm6>
                  <v-text-field
                    v-model="object.deviceEui"
                    :label="$t(`${objectName.multiple}.deviceEui`)"
                    prepend-icon="mdi-label-variant"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.deviceEui !== undefined"
                    :error-messages="parseErrors(objectErrors.deviceEui)"
                  />
                </v-flex>

                <v-flex class="pl-1 pr-1" xs12 sm6>
                  <v-text-field
                    v-model="object.appEui"
                    :label="$t(`${objectName.multiple}.appEui`)"
                    prepend-icon="mdi-label-variant"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.appEui !== undefined"
                    :error-messages="parseErrors(objectErrors.appEui)"
                  />
                </v-flex>

                <v-flex class="pl-1 pr-1" xs12 sm6>
                  <v-text-field
                    v-model="object.appKey"
                    :label="$t(`${objectName.multiple}.appKey`)"
                    prepend-icon="mdi-label-variant"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.appKey !== undefined"
                    :error-messages="parseErrors(objectErrors.appKey)"
                  />
                </v-flex>

                <!-- <v-flex class="pl-1 pr-1" xs12 sm6>
                  <v-text-field
                    v-model="object.localContactName"
                    :label="$t(`${objectName.multiple}.localContactName`)"
                    prepend-icon="mdi-account-outline"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.localContactName !== undefined"
                    :error-messages="parseErrors(objectErrors.localContactName)"
                  />
                </v-flex>

                <v-flex class="pl-1 pr-1" xs12 sm6>
                  <v-text-field
                    v-model="object.localContactPhone"
                    :label="$t(`${objectName.multiple}.localContactPhone`)"
                    prepend-icon="mdi-phone"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.localContactPhone !== undefined"
                    :error-messages="parseErrors(objectErrors.localContactPhone)"
                  />
                </v-flex> -->

                <v-flex class="pl-1 pr-1" xs12 sm6>
                  <v-text-field
                    v-model="object.latitude"
                    :label="$t(`${objectName.multiple}.latitude`)"
                    :disabled="!isEditting"
                    type="number"
                    max="90"
                    min="-90"
                    :color="inputColor"
                    :error="objectErrors.latitude !== undefined"
                    :error-messages="parseErrors(objectErrors.latitude)"
                  />
                </v-flex>

                <v-flex class="pl-1 pr-1" xs12 sm6>
                  <v-text-field
                    v-model="object.longitude"
                    :label="$t(`${objectName.multiple}.longitude`)"
                    :disabled="!isEditting"
                    type="number"
                    max="180"
                    min="-180"
                    :color="inputColor"
                    :error="objectErrors.longitude !== undefined"
                    :error-messages="parseErrors(objectErrors.longitude)"
                  />
                </v-flex>

                <v-flex class="pl-1 pr-1" xs12 sm6>
                  <v-select
                    v-model="object.protocol"
                    :items="[{ value: 'BT', name: $t(`${objectName.multiple}.protocols.bt`) },{ value: 'SAT', name: $t(`${objectName.multiple}.protocols.sat`) }]"
                    :label="$t(`${objectName.multiple}.protocol`)"
                    item-text="name"
                    item-value="value"
                    prepend-icon="mdi-call-merge"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.protocol !== undefined"
                    :error-messages="parseErrors(objectErrors.protocol)"
                  />
                </v-flex>

                <v-flex class="pl-1 pr-1" xs12 sm6>
                  <v-autocomplete
                    v-model="object.location"
                    :items="locations"
                    :label="$t(`${objectName.multiple}.location`)"
                    prepend-icon="mdi-nas"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.location !== undefined"
                    :error-messages="parseErrors(objectErrors.location)"
                  >
                    <template slot="item" slot-scope="{item}">
                      <span>{{ item.locationName }}</span>
                      <v-spacer />
                      <small style="opacity: 0.5">{{ $t(`locations.${item.locationType.toLowerCase()}`) }}</small>
                    </template>

                    <template slot="selection" slot-scope="{item}">
                      <span>{{ item.locationName }} <small style="opacity: 0.5">({{ $t(`locations.${item.locationType.toLowerCase()}`) }})</small></span>
                    </template>
                  </v-autocomplete>
                </v-flex>

                <v-flex class="pl-1 pr-1" xs12 md6>
                  <v-autocomplete
                    v-model="object.loraNodeId"
                    :items="loraNodes"
                    :label="$t(`${objectName.multiple}.loraNode`)"
                    prepend-icon="mdi-access-point"
                    item-value="id"
                    item-text="name"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.loraNodeId !== undefined"
                    :error-messages="parseErrors(objectErrors.loraNodeId)"
                  />
                </v-flex>
              </v-layout>
            </v-tab-item>
            <v-tab-item class="px-5">
              <v-layout row wrap>
                <v-flex v-if="isEditting" xs12 class="mt-2" style="text-align: right;">
                  <v-btn
                    color="white--text"
                    text
                    style="margin: 0;"
                    @click="addRule"
                  >
                    {{ $t('loraNodes.ruleNotifications.add') }}
                    &nbsp;
                    <v-icon class="yellow-color-text">mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </v-flex>

                <v-flex xs12>
                  <v-data-table
                    :headers="rulesHeaders"
                    :disable-pagination="true"
                    hide-default-footer
                    :items="object.ruleNotifications"
                    :options.sync="pagination"
                    :footer-props="{
                      'next-icon': 'mdi-chevron-right',
                      'prev-icon': 'mdi-chevron-left'
                    }"
                  >
                    <template v-slot:body="{ items }">
                      <tr v-for="(item, index) in items" :key="item.id">
                        <td>{{ item.name }}</td>
                        <!-- <td></td> -->
                        <td>{{ $t(`loraNodes.ruleNotifications.calculation.${item.calculation.type.toLowerCase()}`) }}</td>
                        <td style="text-align: right; padding: 0;">
                          <v-btn
                            text
                            icon
                            rounded
                            color="warning"
                            @click="editRule(index)"
                          >
                            <v-icon>mdi-file-document-edit-outline</v-icon>
                          </v-btn>

                          <v-btn
                            v-if="isEditting"
                            text
                            icon
                            rounded
                            color="error"
                            @click="deleteRule(index)"
                          >
                            <v-icon>mdi-file-cancel-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <a-button v-if="object.id !== undefined" :state="buttonState" color="primary" @click="saveChanges">{{ $t('actions.save') }}</a-button>
          
          <a-button v-else-if="object.id === undefined" :state="buttonState" color="primary" @click="createItem">{{ $t('actions.create', { name: $t(`${objectName.multiple}.objectName`) }) }}</a-button>
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.close') }}</v-btn>
          
          <v-spacer />
          
          <v-btn color="primary" @click="editItem(object.id)">{{ $t('actions.edit', { name: $t(`${objectName.multiple}.objectName`)}) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      id="ruleModal"
      v-model="ruleModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-card-title class="headline primary elevation-3" style="text-align: center; max-height: 58px;"><h5 class="white--text ma-0">{{ ruleTitle }}</h5></v-card-title>
        <v-card-text style="flex: 1;" class="pa-0">
          <v-tabs 
            background-color="primary"
            class="text--white"
            slider-color="primary"
            grow
            dark
          >
            <v-tab style="font-weight: bold;">{{ $t(`${objectName.multiple}.basicInformation`).toUpperCase() }}</v-tab>
            <v-tab style="font-weight: bold;">{{ $t(`${objectName.multiple}.message`).toUpperCase() }}</v-tab>
            <v-tab style="font-weight: bold;">{{ $t(`${objectName.multiple}.messageVoice`).toUpperCase() }}</v-tab>
            <v-tab style="font-weight: bold;">{{ $t(`${objectName.multiple}.deliveryMethodsLabel`).toUpperCase() }}</v-tab>
            <v-tab-item class="px-5">
              <v-layout row wrap>
                <v-flex class="pl-2 pr-2" xs12 md3>
                  <v-text-field
                    v-model="ruleObject.name"
                    :label="$t('loraNodes.ruleNotifications.name')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>
                
                <v-flex class="pl-2 pr-2" xs12 md3>
                  <v-select
                    v-model="ruleObject.categoryAlertId"
                    :items="categoryAlertsLoraSensor"
                    :label="$t('loraNodes.ruleNotifications.types.title')"
                    :disabled="!isEditting"
                    :color="inputColor"
                    item-text="name"
                    item-value="id"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2" xs12 md3>
                  <v-select
                    v-model="ruleObject.priorityId"
                    :items="priorities"
                    :label="$t('loraNodes.ruleNotifications.priority')"
                    :disabled="!isEditting"
                    :color="inputColor"
                    item-text="name"
                    item-value="id"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2" xs12 md3>
                  <v-select
                    v-model="ruleObject.calculation.type"
                    :items="[
                      { text: $t('loraNodes.ruleNotifications.calculation.range'), value: 'RANGE' },
                      { text: $t('loraNodes.ruleNotifications.calculation.mask'), value: 'MASK' }
                    ]"
                    :label="$t('loraNodes.ruleNotifications.calculation.title')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>
              </v-layout>

              <v-layout v-if="ruleObject.calculation.type === 'RANGE'" row wrap>
                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-select
                    v-model="ruleObject.calculation.operatorMinimum"
                    :items="['>', '>=', '<', '<=']"
                    :label="$t('loraNodes.ruleNotifications.calculation.operatorMinimum')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-select
                    v-model="ruleObject.calculation.operatorMaximum"
                    :items="['>', '>=', '<', '<=']"
                    :label="$t('loraNodes.ruleNotifications.calculation.operatorMaximum')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-text-field
                    v-model="ruleObject.calculation.valueMinimum"
                    :label="$t('loraNodes.ruleNotifications.calculation.valueMinimum')"
                    :disabled="!isEditting"
                    type="number"
                    :color="inputColor"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-text-field
                    v-model="ruleObject.calculation.valueMaximum"
                    :label="$t('loraNodes.ruleNotifications.calculation.valueMaximum')"
                    :disabled="!isEditting"
                    type="number"
                    :color="inputColor"
                  />
                </v-flex>
              </v-layout>

              <v-layout v-else row wrap>
                <v-flex class="pl-2 pr-2" xs12>
                  <v-text-field
                    v-model="ruleObject.calculation.mask"
                    :label="$t('loraNodes.ruleNotifications.calculation.mask')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>
              </v-layout>
            </v-tab-item>
            <v-tab-item class="px-5">
              <v-layout row wrap pa-4 align-center justify-center>
                <v-flex xs12 pa-1>
                  <v-textarea
                    v-model="ruleObject.message"
                    :label="$t(`${objectName.multiple}.message`)"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.message !== undefined"
                    :error-messages="parseErrors(objectErrors.message)"
                  />
                </v-flex>

                <v-flex xs12>
                  <h5>
                    {{ $t(`${objectName.multiple}.variables`) }}
                  </h5>
                  <v-data-table
                    :headers="messageHeaders"
                    :items="messageValues"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:body="{ items }">
                      <tr v-for="item in items" :key="item.id">
                        <td>{{ item.name }}</td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-tab-item>
            <v-tab-item class="px-5">
              <v-layout row wrap pa-4 align-center justify-center>
                <v-flex xs12 pa-1>
                  <v-textarea
                    v-model="ruleObject.messageVoice"
                    :label="$t(`${objectName.multiple}.messageVoice`)"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.message !== undefined"
                    :error-messages="parseErrors(objectErrors.messageVoice)"
                  />
                </v-flex>

                <v-flex xs12>
                  <h5>
                    {{ $t(`${objectName.multiple}.variables`) }}
                  </h5>
                  <v-data-table
                    :headers="messageHeaders"
                    :items="messageValues"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:body="{ items }">
                      <tr v-for="item in items" :key="item.id">
                        <td>{{ item.name }}</td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-tab-item>
        
            <v-tab-item class="px-5">
              <v-layout row wrap pa-4 align-center justify-center>
                <v-flex xs12>
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <v-switch 
                        v-model="ruleObject.deliveryMethods.email.active"
                        :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.email`)"
                      />
                    </v-flex>

                    <v-flex v-if="ruleObject.deliveryMethods.email.active" xs12 md8>
                      <v-autocomplete
                        v-model="ruleObject.deliveryMethods.email.destinations"
                        :items="companyUsers"
                        :label="$t(`helpers.destinations`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                        multiple
                        small-chips
                        item-text="username"
                        item-value="id"
                      />                     
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <v-switch 
                        v-model="ruleObject.deliveryMethods.telegram.active"
                        :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.telegram`)"
                      />
                    </v-flex>

                    <v-flex v-if="ruleObject.deliveryMethods.telegram.active" xs12 md8>
                      <v-text-field
                        v-model="ruleObject.deliveryMethods.telegram.chatId"
                        :label="$t(`helpers.chatId`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                      />                        
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12 sm4>
                      <v-switch 
                        v-model="ruleObject.deliveryMethods.sendRequest.active"
                        :disabled="!isEditting"
                        :label="$t(`${objectName.multiple}.deliveryMethods.sendRequest`)" 
                      />
                    </v-flex>
                    <v-flex v-if="ruleObject.deliveryMethods.sendRequest.active" xs12 sm4 pa-2>
                      <v-select
                        v-model="ruleObject.deliveryMethods.sendRequest.type"
                        :items="['GET', 'POST']"
                        :disabled="!isEditting"
                        :label="$t(`helpers.requestType`)"
                        :color="inputColor"
                        :dark="dark"
                      />
                    </v-flex>

                    <v-flex v-if="ruleObject.deliveryMethods.sendRequest.active" xs12 sm4 pa-2>
                      <v-text-field
                        v-model="ruleObject.deliveryMethods.sendRequest.url"
                        :label="$t(`helpers.url`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12 md12>
                      <v-switch 
                        v-model="ruleObject.deliveryMethods.twilio.active"
                        :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.twilio`)"
                      />
                    </v-flex>

                    <v-flex v-if="ruleObject.deliveryMethods.twilio.active" xs12 md6>
                      <v-autocomplete
                        v-model="ruleObject.deliveryMethods.twilio.destinations"
                        :items="companyUsers"
                        :label="$t(`helpers.destinations`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                        multiple
                        small-chips
                        item-text="username"
                        item-value="id"
                      />                      
                    </v-flex>
                    <v-flex v-if="ruleObject.deliveryMethods.twilio.active" xs12 md6>
                      <v-autocomplete
                        v-model="ruleObject.deliveryMethods.twilio.callDestinations"
                        :items="companyUsers"
                        :label="$t(`helpers.callDestinations`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                        multiple
                        small-chips
                        item-text="username"
                        item-value="id"
                      />                      
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn v-if="ruleStateNew" color="error" @click="discardNewRule">{{ $t('actions.discard') }}</v-btn>

          <v-btn v-else="" color="error" @click="discardRule">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <v-btn color="success" @click="saveRule">{{ $t('actions.save') }}</v-btn>
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardRule">{{ $t('actions.discard') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline" style="font-size: 1.2em !important; text-align: center;">{{ $t('helpers.confirmDelete', { name: $t(`${objectName.multiple}.objectName`) }) }}</v-card-title>

        <v-card-actions style="text-align: center;">
          <v-spacer />

          <v-btn
            color="error"
            flat
            icon
            @click="deleteConfirm = false; deleteDialog = false; deleteId = 0"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

          <v-btn
            color="success"
            flat
            icon
            @click="deleteConfirmation"
          >
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'

import { mapState, mapGetters } from 'vuex'

import add from '@/graphql/mutations/loraSensors/add.gql'
import edit from '@/graphql/mutations/loraSensors/edit.gql'
import object from '@/graphql/queries/loraSensors/detail.gql'
import moment from 'moment'

const objectName = {
  single: 'loraSensor',
  multiple: 'loraSensors',
  object: {
    single: 'LoraSensor',
    multiple: 'LoraSensors'
  }
}

export default {
  components: {
    AButton
  },

  data () {
    return {
      objectName,
      pagination: { rowsPerPage: 10 },
      formModal: false,
      sensorModal: false,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      deleteId: 0,
      map: null,
      marker: null,
      object: {},
      sensorObj: {},
      objectErrors: {},
      search: '',
      ruleObject: {
        calculation: {},
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: []
            // approved_by_central: false
          }
        }
      },
      ruleModal: false,
      ruleIndex: null,
      ruleStateNew: false,
      headers: [
        // {
        //   text: this.$i18n.t(`${objectName.multiple}.name`),
        //   align: 'left',
        //   sortable: true,
        //   value: 'name'
        // },
        {
          text: this.$i18n.t(`${objectName.multiple}.deviceEui`),
          align: 'left',
          sortable: true,
          value: 'deviceEui'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.bagReference`),
          align: 'left',
          sortable: true,
          value: 'protocol'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.loraNode`),
          align: 'left',
          sortable: true,
          value: 'loraNodeName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.isAlive`),
          align: 'left',
          sortable: true,
          value: 'isAlive'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.updatedAt`),
          align: 'left',
          sortable: true,
          value: 'updatedAt'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.kitReference`),
          align: 'left',
          sortable: true,
          value: 'kitReference'
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ]
      // sensorsHeaders: [
      //   {
      //     text: this.$i18n.t(`${objectName.multiple}.btSensors.sensorId`),
      //     align: 'left',
      //     sortable: true,
      //     value: 'sensorId'
      //   },
      //   {
      //     text: this.$i18n.t(`${objectName.multiple}.btSensors.parameters`),
      //     align: 'left',
      //     sortable: true,
      //     value: 'parameters'
      //   },
      //   {
      //     text: this.$i18n.t('helpers.actions'),
      //     align: 'right',
      //     sortable: false,
      //     value: 'actions'
      //   }
      // ]
    }
  },

  computed: {
    ...mapState(['user', 'width', 'dark', 'language', 'priorities']),
    ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor', 'locations', 'categoryAlertsLoraSensor', 'companyUsers']),
    messageHeaders () {
      return [
        {
          text: this.$i18n.t(`variables.name`),
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$i18n.t(`variables.variable`),
          align: 'left',
          sortable: true,
          value: 'variable'
        }
      ]
    },
    messageValues () {
      return [
        {
          name: this.$i18n.t(`variables.nodeName`),
          value: '{nodeName}'
        },
        {
          name: this.$i18n.t(`variables.nodeSubscriber`),
          value: '{nodeSubscriber}'
        },
        {
          name: this.$i18n.t(`variables.sensorDevice`),
          value: '{sensorDevice}'
        },
        {
          name: this.$i18n.t(`variables.sensorBT`),
          value: '{sensorBT}'
        },
        {
          name: this.$i18n.t(`variables.bagReference`),
          value: '{bagReference}'
        },
        {
          name: this.$i18n.t(`variables.bagNomenclature`),
          value: '{bagNomenclature}'
        },
        {
          name: this.$i18n.t(`variables.bagCycle`),
          value: '{bagCycle}'
        },
        {
          name: this.$i18n.t(`variables.bagProduct`),
          value: '{bagProduct}'
        },
        {
          name: this.$i18n.t(`variables.bagFieldBatch`),
          value: '{bagFieldBatch}'
        },
        {
          name: this.$i18n.t(`variables.messageId`),
          value: '{messageId}'
        },
        {
          name: this.$i18n.t(`variables.messageTime`),
          value: '{messageTime}'
        },
        {
          name: this.$i18n.t(`variables.messageTemps`),
          value: '{messageTemps}'
        },
        {
          name: this.$i18n.t(`variables.messageVbats`),
          value: '{messageVbats}'
        },
        {
          name: this.$i18n.t(`variables.messageVbatg`),
          value: '{messageVbatg}'
        },
        {
          name: this.$i18n.t(`variables.messageEvent`),
          value: '{messageEvent}'
        }
      ]
    },
    rulesHeaders () {
      return [
        {
          text: this.$i18n.t('loraNodes.ruleNotifications.name'),
          value: 'name',
          sortable: false
        },
        // {
        //   text: this.$i18n.t('loraNodes.ruleNotifications.types.title'),
        //   value: 'types',
        //   sortable: false
        // },
        {
          text: this.$i18n.t('loraNodes.ruleNotifications.calculation.title'),
          value: 'calculation',
          sortable: false
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          value: 'actions',
          sortable: false
        }
      ]
    },
    loraNodes () {
      return [
        {
          id: null,
          name: 'NA'
        },
        ...this.$store.state.loraNodes
      ]
    },

    defaultObject () {
      return {
        id: undefined,
        name: '',
        loraNodeId: null,
        protocol: 'SAT',
        location: {},
        deviceEui: '',
        appKey: '11477472381147747238114774723811',
        appEui: '4774723847747238',
        localContactName: '',
        localContactPhone: '',
        latitude: 0.0,
        longitude: 0.0,
        ruleNotifications: []
      }
    },

    isFullScreen () {
      return this.width.full < 990
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.name })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.name })
        }
      }
    },
    ruleTitle () {
      return this.$i18n.t(`${objectName.multiple}.ruleNotifications.title`, { name: this.ruleObject.name })
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted () {
    const card = document.querySelector('#sensorsCard')
    const search = document.getElementById('sensorsSearchFixed')

    var observer = new ResizeObserver(function (entries) {
      entries.forEach(function (entry) {
        search.style.width = `${card.offsetWidth}px`
      })
    })

    observer.observe(card)
  },

  methods: {
    handleScroll (event) {
      const elem = document.getElementById('sensorsCard')
      const search = document.getElementById('sensorsSearchFixed').childNodes[0]
      const searchCard = document.getElementById('sensorsSearch')

      var posTopView = window.scrollY
      var elemTop = elem.offsetTop + 126

      if (elemTop > posTopView) {
        search.classList.remove('visible')
        if (this.keyFocus) {
          searchCard.focus()
          this.keyFocus = false
        }
      } else {
        search.classList.add('visible')
        this.keyFocus = true
      }
    },

    parseDate (date) {
      return moment.unix(date).format('YYYY-MM-DD HH:mm:ss')
    },
    addRule () {
      this.object.ruleNotifications.push({
        id: null,
        name: '',
        priorityId: 0,
        categoryAlertId: 0,
        message: '',
        messageVoice: '',
        calculation: {
          type: 'RANGE',
          operatorMinimum: '>=',
          operatorMaximum: '<=',
          valueMinimum: 0,
          valueMaximum: 0,
          mask: '%val%'
        },
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: []
            // approved_by_central: false
          }
        }
      })
      this.ruleObject = Object.assign({}, {
        id: null,
        name: '',
        priorityId: 0,
        categoryAlertId: 0,
        message: '',
        messageVoice: '',
        calculation: {
          type: 'RANGE',
          operatorMinimum: '>=',
          operatorMaximum: '<=',
          valueMinimum: 0,
          valueMaximum: 0,
          mask: '%val%'
        },
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: []
            // approved_by_central: false
          }
        }
      })
      this.ruleModal = true
      this.ruleStateNew = true
      this.ruleIndex = this.object.ruleNotifications.length - 1
    },

    discardNewRule () {
      this.ruleModal = false
      this.ruleObject = { 
        calculation: {},
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: []
            // approved_by_central: false
          }
        }
      }
      this.object.ruleNotifications.pop()
      this.ruleIndex = null
    },

    editRule (i) {
      this.ruleIndex = i
      this.ruleObject = Object.assign({}, this.object.ruleNotifications[i])
      
      this.ruleModal = true
    },

    saveRule () {
      if (this.ruleIndex !== null) {
        this.object.ruleNotifications[this.ruleIndex] = Object.assign({}, this.ruleObject)
        this.ruleModal = false
        this.ruleIndex = null

        const rules = this.object.ruleNotifications
        this.object.ruleNotifications = []
        this.object.ruleNotifications = Object.values(Object.assign({}, rules))
      }
    },

    discardRule () {
      this.ruleModal = false
      this.ruleObject = { calculation: {},
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: []
          // approved_by_central: false
          }
        }
      }
      this.ruleIndex = null
    },

    deleteRule (i) {
      this.object.ruleNotifications.splice(i, 1)
      this.discardRule()
    },
    parseErrors (array) {
      if (array === undefined) {
        return []
      }

      return array.map((currentValue, index, array) => {
        return currentValue.charAt(0).toUpperCase() + currentValue.substr(1)
      }).join(', ')
    },

    saveChanges () {
      this.graphQLWrapper(`edit${objectName.object.single}`)
    },

    async editItem (id) {
      if (this.object.id === undefined) {
        await this.showItem(id)
      }
      
      this.isEditting = true
    },

    async showItem (id) {
      await this.$apollo.query({
        query: object,
        variables: {
          id,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const result = response.data[objectName.single]
        
        if (result.status) {
          this.object = {
            ...result[objectName.single]
          }

          delete this.object.location.__typename

          this.formModal = true
          this.isEditting = false
        } else {
          if (errors.limit === undefined) {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: (errors.id === undefined ? errors.apiToken : errors.id)
            })
          } else {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: errors.limit
            })
          }
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      })
    },

    discardChanges () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = false
      this.isEditting = false
    },

    newItem () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = true
      this.isEditting = true
    },

    createItem () {
      this.graphQLWrapper(`add${objectName.object.single}`)
    },

    graphQLWrapper (mutationName) {
      this.buttonState = true
      this.objectErrors = {}
      const variables = {
        object: this.object,
        apiToken: this.user.apiToken,
        language: this.language.toUpperCase()
      }

      delete variables.object.__typename
      delete variables.object.location.__typename

      variables.object.latitude = parseFloat(variables.object.latitude)
      variables.object.longitude = parseFloat(variables.object.longitude)
      
      for (const i in variables.object.ruleNotifications) {
        delete variables.object.ruleNotifications[i].__typename

        variables.object.ruleNotifications[i].calculation.valueMinimum = parseFloat(variables.object.ruleNotifications[i].calculation.valueMinimum)
        variables.object.ruleNotifications[i].calculation.valueMaximum = parseFloat(variables.object.ruleNotifications[i].calculation.valueMaximum)

        delete variables.object.ruleNotifications[i].calculation.__typename
      }
      let mutation = add

      if (mutationName.includes('edit')) {
        mutation = edit
      }

      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        const { status, errors, result } = response.data[mutationName]

        if (status === 'OK') {
          this.object = {
            ...result
          }
          delete this.object.location.__typename
          this.objectErrors = {}
          this.isEditting = false
          this.$store.dispatch(`get${objectName.object.multiple}`)
          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`${objectName.multiple}.success.${(mutationName.includes('edit') ? 'updated' : 'created')}`, { name: result.name })
          })
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },

    async deleteItem (id) {
      this.deleteId = id
      this.deleteDialog = true
    },

    async deleteConfirmation () {
      await this.$store.dispatch('deleteItem', {
        mutation: {
          graphql: `delete${objectName.object.single}`,
          vuex: `get${objectName.object.multiple}`
        },
        object: objectName.multiple,
        variables: {
          id: this.deleteId,
          apiToken: this.user.apiToken,
          language: this.language
        }
      })

      this.deleteDialog = false
      this.deleteId = 0
      this.deleteConfirm = false
    }
  }
}
</script> 