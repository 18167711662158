<template>
  <v-navigation-drawer
    v-model="sidebarState"
    :permanent="width.full >= 960"
    :temporary="width.full < 960"
    :expand-on-hover="mini && width.full >= 960"
    mobile-break-point="960"
    dark
    overflow
    app
    class="elevation-5"
  >
    <v-list
      dense
      nav
      class="py-0"
    >
      <v-list-item dense one-line>
        <v-list-item-avatar tile>
          <img :src="$assets.logo">
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title style="display: flex; align-items: center; justify-content: space-between;">
            <img style="width: 80%; height:auto" :src="$assets.logo2">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn v-if="width.full >= 960" v-on="on" small icon @click="setMini(!mini)">
                  <v-icon small>mdi-resize</v-icon>
                </v-btn>
              </template>
              <span>{{ $t(`app.tools.compact.${mini}`) }}</span>
            </v-tooltip>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mb-1" />
      
      <v-list-group v-model="userMenu" color="transparent">
        <template v-slot:activator>
          <v-list-item dense one-line class="margin-zero" style="margin: 0px;">
            <v-list-item-avatar>
              <img style="border-radius: 5px;" :src="$assets.avatar">
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title><h5 class="pa-0 ma-0">{{ lengthCutter(user.name, 10) }}</h5></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content @click="goToUserInformation">
            <v-list-item-title>{{ $t('panel.userInformation') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>

          <v-list-item-content @click="logout({ router: $router, i18n: $i18n })">
            <v-list-item-title>{{ $t('login.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

    <v-list
      dense
      nav
      class="py-0"
    >
      <v-divider class="mb-1" />

      <v-list-item v-for="link in links" :key="link.name" :to="link.to" dense link active-class="active-tab">
        <v-list-item-icon class="text-md-center">
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ lengthCutter($t(`pages.${link.name}`)) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  data () {
    return {
      userMenu: false
    }
  },

  watch: {
    mini (newVal, oldVal) {
      
    }
  },

  computed: {
    ...mapState(['width', 'user', 'sidebar', 'mini']),
    ...mapGetters(['inputColor']),

    sidebarState: {
      get () {
        return this.sidebar
      },
      set (value) {
        this.setSidebar(value)
      }
    },

    favicon () {
      if (this.$assets.favicon.normal === undefined) {
        return this.$assets.favicon
      } else {
        return this.$assets.favicon.normal
      }
    },

    appName () {
      return this.$i18n.t('app.title')
    },

    links () {
      let links = [
        
      ]

      switch (this.user.parentType) {
        case 'Company::Central':
          links = [
            ...links,
            {
              to: '/Platform/Home',
              icon: 'mdi-view-dashboard',
              name: 'cmsHome'
            },
            {
              to: '/Platform/Notifications',
              icon: 'mdi-alert',
              name: 'platformNotifications'
            },
            {
              to: '/Platform/Incidents',
              icon: 'mdi-file',
              name: 'platformIncidents'
            },
            {
              to: '/Platform/Map',
              icon: 'mdi-map',
              name: 'platformMap'
            },
            {
              to: '/Platform/Lora/Nodes',
              icon: 'mdi-access-point-network',
              name: 'platformLoraNodes'
            },
            {
              to: '/Platform/Lora/Sensors',
              icon: 'mdi-leak',
              name: 'platformLoraSensors'
            },
            {
              to: '/Platform/Kits',
              icon: 'mdi-package-variant',
              name: 'platformKits'
            },
            {
              to: '/Platform/LoadKits',
              icon: 'mdi-file-upload-outline',
              name: 'platformLoadKits'
            },
            {
              to: '/Platform/FieldBatches',
              icon: 'mdi-soccer-field',
              name: 'platformFieldBatches'
            },
            {
              to: '/Platform/Bags',
              icon: 'mdi-food-croissant',
              name: 'platformBags'
            },
            {
              to: '/Platform/HistoricBags',
              icon: 'mdi-food-croissant',
              name: 'platformHistoricBags'
            },
            {
              to: '/Platform/Orders',
              icon: 'mdi-clipboard-text-outline',
              name: 'platformOrders'
            },
            {
              to: '/Platform/OrdersReports',
              icon: 'mdi-clipboard-text-outline',
              name: 'platformOrdersReports'
            },
            {
              to: '/Platform/OrderApproval',
              icon: 'mdi-clipboard-check',
              name: 'platformOrderApproval'
            },
            {
              to: '/Platform/Messages',
              icon: 'mdi-package-down',
              name: 'platformMessages'
            },
            {
              to: '/Platform/Reports',
              icon: 'mdi-file-excel',
              name: 'platformReports'
            },
            {
              to: '/Platform/CompanyLinkRequests',
              icon: 'mdi-link-variant',
              name: 'platformCompanyLinkRequests'
            }
            
          ]
          break

        case 'Company::Promotor':
          links = [
            ...links,
            
            {
              to: '/Platform/FieldBatches',
              icon: 'mdi-soccer-field',
              name: 'platformFieldBatches'
            },
            {
              to: '/Platform/Bags',
              icon: 'mdi-food-croissant',
              name: 'platformBags'
            },
            {
              to: '/Platform/HistoricBags',
              icon: 'mdi-food-croissant',
              name: 'platformHistoricBags'
            },
            {
              to: '/Platform/Orders',
              icon: 'mdi-clipboard-text-outline',
              name: 'platformOrders'
            },  
          ]
          break

        case 'Company::Local':
          links = [
            ...links,
            {
              to: '/Platform/Kits',
              icon: 'mdi-package-variant',
              name: 'platformKits'
            },
            {
              to: '/Platform/FieldBatches',
              icon: 'mdi-soccer-field',
              name: 'platformFieldBatches'
            },
            {
              to: '/Platform/Bags',
              icon: 'mdi-food-croissant',
              name: 'platformBags'
            },
            {
              to: '/Platform/HistoricBags',
              icon: 'mdi-food-croissant',
              name: 'platformHistoricBags'
            },
            {
              to: '/Platform/Orders',
              icon: 'mdi-clipboard-text-outline',
              name: 'platformOrders'
            },
            {
              to: '/Platform/OrdersReports',
              icon: 'mdi-clipboard-text-outline',
              name: 'platformOrdersReports'
            },
          ]
          break

        default:
          links = [
            ...links,
            {
              to: '/Platform/Kits',
              icon: 'mdi-package-variant',
              name: 'platformKits'
            },
            {
              to: '/Platform/FieldBatches',
              icon: 'mdi-soccer-field',
              name: 'platformFieldBatches'
            },
            {
              to: '/Platform/Bags',
              icon: 'mdi-food-croissant',
              name: 'platformBags'
            },
            {
              to: '/Platform/HistoricBags',
              icon: 'mdi-food-croissant',
              name: 'platformHistoricBags'
            },
          ]
          break
      }

      return links
    }
  },
  created () {
    const id = setInterval(this.updateMessages, 60000)
    this.$store.commit('setIntervalId', id)
    const allId = setInterval(this.updateAll, 60000 * 5)
    this.$store.commit('setIntervalAllId', allId)
  },
  methods: {
    ...mapActions({
      logout: 'signOut'
    }),
    ...mapMutations(['setSidebar', 'setMini']),
    goToUserInformation () {
      this.$router.push('/Platform/userInformation')
    },
    lengthCutter (string, size = 15) {
      if (string === undefined) {
        return ''
      }

      if (string.length > size) {
        return `${string.substring(0, size)}...`
      } else {
        return string
      }
    },
    updateMessages () {
      if (process.env.NODE_ENV === 'production') {
        this.$store.dispatch('getMessagesMonitor')
      }
    },
    updateAll () {
      if (process.env.NODE_ENV === 'development') {
        this.$store.dispatch('getLoraNodes')
        this.$store.dispatch('getUsersSelect')
        this.$store.dispatch('getLoraSensors')
        this.$store.dispatch('getBtSensors')
        this.$store.dispatch('getCycles')
        this.$store.dispatch('getFieldBatches')
        this.$store.dispatch('getProducts')
        this.$store.dispatch('getKits')
        this.$store.dispatch('getNotificationGroups')
        this.$store.dispatch('getCompanyLocalLinkRequests')
        this.$store.dispatch('getBags')
        this.$store.dispatch('getCategoryOrders')
        this.$store.dispatch('getMessages')
        // if (this.$store.state.user.parentType !== 'Company::Depository') {
        //   this.$store.dispatch('getMessagesReport')
        // }
        this.$store.dispatch('getCategoryAlerts')
        this.$store.dispatch('getPriorities')
        this.$store.dispatch('getIncidents')
      }
    }
  }
}
</script>

<style lang="scss">
  .v-list-item__icon.v-list-group__header__append-icon {
    padding: 0;
    margin-top: 18px !important;
    color: #fff;
  }

  .active-tab {
    color: #fbc206 !important;
    background-color: var(--v-anchor-base);
  }

  .theme--dark.v-list-item--active:before, .theme--dark.v-list-item--active:hover:before, .theme--dark.v-list-item:focus:before {
    opacity: 0 !important;
  }

  .margin-zero {
    margin: 10px, 0px !important
  }
</style>
