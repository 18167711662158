<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <div id="reportsSearchFixed">
        <div class="searchFixed">
          <v-card outlined elevation="2" class="ma-3">
            <v-card-text>
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('actions.search')"
                single-line
                :color="inputColor"
                hide-details
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
      <v-flex xs12>
        <v-card
          id="cardWidth"
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12 sm6 px-2>
                <v-dialog
                  ref="start"
                  v-model="modal"
                  :return-value.sync="object.startDate"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="object.startDate"
                      :label="$t(`reports.startDate`)" 
                      :disabled="!isEditting"
                      :color="inputColor"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker v-model="object.startDate" scrollable>
                    <v-spacer />
                    <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.start.save(object.startDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs12 sm6 px-2>
                <v-dialog
                  ref="dialog2"
                  v-model="modalEnd"
                  :return-value.sync="object.endDate"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="object.endDate"
                      :label="$t(`reports.endDate`)" 
                      :disabled="!isEditting"
                      :color="inputColor"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker v-model="object.endDate" :min="object.startDate" scrollable>
                    <v-spacer />
                    <v-btn flat color="primary" @click="modalEnd = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialog2.save(object.endDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs12 sm6 px-2>
                <v-autocomplete
                  v-model="object.loraNodesIds"
                  :multiple="true"
                  :items="loraNodes"
                  :label="$t(`reports.loraNodesIds`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="id"
                  @change="fieldBatchesOptionsAdjust"
                />
              </v-flex>
              <v-flex xs12 sm6 px-2>
                <v-autocomplete
                  v-model="object.fieldBatchesIds"
                  :multiple="true"
                  :items="fieldBatchesOptions"
                  :label="$t(`reports.fieldBatchesIds`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="id"
                  @change="bagsOptionsAdjust"
                />
              </v-flex>
              <v-flex xs12 :class="user.parentType === 'Company::Central' ? 'sm6' : ''" px-2>
                <v-autocomplete
                  v-model="object.bagsIds"
                  :multiple="true"
                  :items="bagsOptions"
                  :label="$t(`reports.bagsIds`)" 
                  :color="inputColor"
                  item-text="reference"
                  item-value="id"
                />
              </v-flex>
              <v-flex v-if="user.parentType === 'Company::Central'" xs12 sm6 px-2>
                <v-autocomplete
                  v-model="object.events"
                  :multiple="true"
                  :items="events"
                  :label="$t(`reports.events`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="id"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          
          <v-card-actions>
            <a-button :state="buttonState" color="primary" @click="send">{{ $t('actions.executeReport') }}</a-button>
            <v-spacer />
            <a-button v-if="retrieved" :state="buttonState" color="primary" @click="executeReport">{{ $t('actions.exportReport') }}</a-button>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 class="my-4">
        <v-card
          v-if="retrieved"
          id="reportsCard"
          elevation="primary"
        >
          <!-- <v-layout slot="header" row wrap justify-space-between align-center>
            <h4 style="padding: 0; margin: 0;">{{ $t(`pages.${$route.name}Preview`) }}</h4>
          </v-layout> -->
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}Preview`) }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              id="reportsSearch"
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
            />
            <v-data-table
              fixed-header
              height="600"
              :headers="headers"
              :items="messages"
              :search="search"
              :options.sync="pagination"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left'
              }"
            >
              <template v-slot:body="{ items }">
                <tr v-for="item in items" :key="item.id">
                  <td class="text-xs-right">
                    {{ item.message_id }}
                  </td>
                  <td class="text-xs-right">{{ date(item.message_time) }} <br> {{ time(item.message_time) }}</td>
                  <td>{{ item.subscriber_id }}</td>
                  <td class="text-xs-right">{{ item.sensor }}</td>
                  <td>{{ item.my_event_name }}</td>
                  <td>{{ item.bag_name }}</td>
                  <td>{{ item.nomenclature }}</td>
                  <td>{{ item.field_batch }}</td>
                  <td>{{ item.lora_node }}</td>
                  <td>{{ item.lora_sensor }}</td>
                  <td>{{ item.bt_sensor }}</td>
                  <td class="text-xs-right">{{ item.my_sequence }}</td>
                  <td class="text-xs-right">{{ item.temps }}</td>
                  <td class="text-xs-right">{{ item.vbats }}</td>
                  <td class="text-xs-right">{{ item.vbatg }}</td>
                  <td>{{ item.message_virtual ? 'si' : 'no' }}</td>
                </tr>
              </template>
              <tr slot="no-results">
                <td :colspan="headers.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'

import { mapState, mapGetters } from 'vuex'

import list from '@/graphql/queries/reports/list.gql'
import generateReport from '@/graphql/mutations/reports/generateReport.gql'
import moment from 'moment'
import ApolloClient from 'apollo-boost'

const objectName = {
  single: 'bagmessage',
  multiple: 'messages',
  object: {
    single: 'Message',
    multiple: 'Messages'
  }
}

export default {
  components: {
    AButton
  },

  data () {
    return {
      messages: [],
      modal: false,
      modalEnd: false,
      objectName,
      keys: { primary: 'name', secondary: 'deviceEui' },
      i18n: this.$i18n.t('duallist3'),
      dense: true,
      drawingManager: null,
      pagination: { rowsPerPage: 10 },
      formModal: false,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      deleteId: 0,
      map: null,
      geofence: null,
      drawer: null,
      retrieved: false,
      fieldBatchesOptions: [],
      bagsOptions: [],
      object: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loraNodesIds: [],
        fieldBatchesIds: [],
        bagsIds: [],
        events: []
      },
      reportObject: {},
      objectErrors: {},
      search: '',
      events: [
        {
          id: 0,
          name: 'Reporte Periódico Normal'
        },
        {
          id: 10,
          name: 'Reporte Magnético'
        },
        {
          id: 20,
          name: 'Reporte Movimiento'
        },
        {
          id: 100,
          name: 'Falla Reporte Periódico'
        }
      ],
      headers: [
        { 
          align: 'left',
          sortable: true,
          value: 'message_id',
          text: this.$i18n.t(`${objectName.multiple}.id`)
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.messageTime`),
          value: 'message_time'
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.subscriberId`),
          value: 'subscriber_id' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.sensorNumber`),
          value: 'sensor' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.event`),
          value: 'my_event_name' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.bagName`),
          value: 'bag_name' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.bagNomenclature`),
          value: 'nomenclature' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.fieldBatch`),
          value: 'field_batch' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.loraNode`),
          value: 'lora_node' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.loraSensors`),
          value: 'lora_sensor' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.sensors`),
          value: 'bt_sensor' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.sequence`),
          value: 'my_sequence' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.temperature`),
          value: 'temps' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.voltage`),
          value: 'vbats'
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.nodeVoltage`),
          value: 'vbatsg'
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.virtualSensor`),
          value: 'virtual_sensor'
        }
      ]
    }
  },

  computed: {
    ...mapState(['user', 'width', 'dark', 'language', 'bags', 'fieldBatches', 'loraNodes']),
    ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor', 'unlinkedLoraSensors']),
    isFullScreen () {
      return this.width.full < 990
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.reference })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.reference })
        }
      }
    }
  },

  created () {
    this.fieldBatchesOptions = this.fieldBatches
    this.bagsOptions = this.bags
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted () {
    const card = document.querySelector('#cardWidth')
    const search = document.getElementById('reportsSearchFixed')

    var observer = new ResizeObserver(function (entries) {
      entries.forEach(function (entry) {
        search.style.width = `${card.offsetWidth}px`
      })
    })

    observer.observe(card)
  },

  methods: {
    handleScroll (event) {
      if (this.retrieved) {
        const elem = document.getElementById('reportsCard')
        const search = document.getElementById('reportsSearchFixed').childNodes[0]
        const searchCard = document.getElementById('reportsSearch')

        var posTopView = window.scrollY
        var elemTop = elem.offsetTop + 126

        if (elemTop > posTopView) {
          search.classList.remove('visible')
          if (this.keyFocus) {
            searchCard.focus()
            this.keyFocus = false
          }
        } else {
          search.classList.add('visible')
          this.keyFocus = true
        }
      }
    },

    fieldBatchesOptionsAdjust () {
      this.object.fieldBatchesIds = []
      this.object.bagsIds = []
      if (this.object.loraNodesIds.length !== 0) {
        this.fieldBatchesOptions = this.fieldBatches.filter((field) => this.object.loraNodesIds.includes(field.loraNodeId))
      } else {
        this.fieldBatchesOptions = this.fieldBatches
      }
      if (this.object.loraNodesIds.length !== 0) {
        const fieldBatchesIds = this.fieldBatches.filter((field) => this.object.loraNodesIds.includes(field.loraNodeId)).map((field) => field.id)
        
        this.bagsOptions = this.bags.filter((bag) => fieldBatchesIds.includes(bag.fieldBatchId))
      } else {
        this.bagsOptions = this.bags
      }
    },

    bagsOptionsAdjust () {
      this.object.bagsIds = []

      if (this.object.fieldBatchesIds.length !== 0) {
        this.bagsOptions = this.bags.filter((bag) => this.object.fieldBatchesIds.includes(bag.fieldBatchId))
      } else {
        if (this.object.loraNodesIds.length !== 0) {
          const fieldBatchesIds = this.fieldBatches.filter((field) => this.object.loraNodesIds.includes(field.loraNodeId)).map((field) => field.id)
          
          this.bagsOptions = this.bags.filter((bag) => fieldBatchesIds.includes(bag.fieldBatchId))
        } else {
          this.bagsOptions = this.bags
        }
      }
    },
    executeReport () {
      this.buttonState = true
      const variables = {
        ...this.reportObject
      }

      const mutation = generateReport
      const mutationName = 'generateReport'

      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        const { status, errors, result } = response.data[mutationName]

        if (status === 'OK') {
          window.open('/generated/' + result, '_blank')
        } else {
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },
    async send () {
      const client = new ApolloClient({
        uri: '/graphql'
      })
      
      await client.query({
        query: list,
        variables: {
          startDate: moment(this.object.startDate).unix(),
          endDate: moment(this.object.endDate).unix(),
          loraNodesIds: this.object.loraNodesIds,
          fieldBatchesIds: this.object.fieldBatchesIds,
          bagsIds: this.object.bagsIds,
          events: this.user.parentType === 'Company::Central' ? this.object.events : [20],
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { messages, status } = response.data.reportsMessages
        
        if (status === 'OK') {
          this.reportObject = {
            startDate: moment(this.object.startDate).unix(),
            endDate: moment(this.object.endDate).unix(),
            loraNodesIds: this.object.loraNodesIds,
            fieldBatchesIds: this.object.fieldBatchesIds,
            bagsIds: this.object.bagsIds,
            events: this.object.events,
            apiToken: this.user.apiToken,
            language: this.language.toUpperCase()
          }
          this.messages = messages
          this.retrieved = true
        } else {
          
        }
      }).catch((error) => {
      })
    },
    date (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    time (time) {
      return moment(time).format('HH:mm:ss')
    },
    
    updateMessages () {
      this.$store.dispatch('getMessages')
    }  
  }
}
</script> 